export const EmbedStyle = Object.freeze({
  NONE: { key: 'none', name: 'None' },
  PAGE: { key: 'page', name: 'Page' },
  PANEL: { key: 'panel', name: 'Panel' },
})

export const LOCAL_STORAGE_OVERRIDES_KEY = 'launch_darkly_overrides'

export const API_BASE_URL = 'https://ahg75ht186.execute-api.us-east-2.amazonaws.com'

export const env = 'prod'
export const aptos_network = 'mainnet'

export const backend_base_url = 'https://api.moviebonus.com';