import React, { createContext, useState, useContext, useEffect } from 'react';
import { ConsoleLoggerFactory, IFireblocksNCW, InMemorySecureStorageProvider, FireblocksNCWFactory, getFireblocksNCWInstance, TMPCAlgorithm, IEventsHandler, IKeyDescriptor } from "@fireblocks/ncw-js-sdk";
import { backend_base_url } from './constants';

interface AppContextType {
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  deviceId: string;
  setDeviceId: React.Dispatch<React.SetStateAction<string>>;
  userJWT: string;
  setUserJWT: React.Dispatch<React.SetStateAction<string>>;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  pasteCodeResult: string;
  setPasteCodeResult: React.Dispatch<React.SetStateAction<string>>;
  reward: string;
  setReward: React.Dispatch<React.SetStateAction<string>>;
  collectionIds: string[];
  addCollectionId: (collectionId: string) => void;
  removeCollectionId: (index: number) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<string>('');
  const [userJWT, setUserJWT] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [pasteCodeResult, setPasteCodeResult] = useState<string>('');
  const [reward, setReward] = useState<string>('');
  const [collectionIds, setCollectionIds] = useState<string[]>([]);
  
  // Fireblocks State Handlers
  const [keysStatus, setKeysStatus] = useState({} as Record<TMPCAlgorithm, IKeyDescriptor>)
  const [requestId, setRequestId] = useState("");
  const [fbSdk, setFireblocksSdk] = useState({} as IFireblocksNCW);

  // Initiate secure storage to hold generated data during SDK usage.
  const secureStorageProvider = new InMemorySecureStorageProvider();

  const addCollectionId = (collectionId: string) => {
    setCollectionIds((prevCollectionIds) => [...prevCollectionIds, collectionId]);
  };

  const removeCollectionId = (index: number) => {
    setCollectionIds((prevCollectionIds) => prevCollectionIds.filter((_, i) => i != index));
  };

  // Start Fireblocks NCW SDK

  const fireblocks_postCall = async (path: string, body: Object) => {
    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    const response = await fetch(`${backend_base_url}/${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userJWT}`,
      },
      body: JSON.stringify(body ?? {}),
    });

    const responseJson = await response.json();
    if (!response.ok) {
      throw new Error(
        `A call to "${path}" failed with status ${response.status}, data: ${JSON.stringify(responseJson)}`,
      );
    }
    return responseJson;
  }
  const fireblocks_getCall = async (path: string) => {
    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    const response = await fetch(`${backend_base_url}/${path}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${userJWT}`,
      }
    });

    const responseJson = await response.json();
    if (!response.ok) {
      throw new Error(
        `A call to "${path}" failed with status ${response.status}, data: ${JSON.stringify(responseJson)}`,
      );
    }
    return responseJson;
  }

  // Start -> FB Generate Device Id

  const DEVICE_ID_KEY = "WICKED_APP:deviceId";

  const generateDeviceId = () => crypto.randomUUID();

  const loadDeviceId = (userId: string) => {
    return localStorage.getItem(`${DEVICE_ID_KEY}-${userId}`);
  };

  const storeDeviceId = (deviceId: string, userId: string) => {
    localStorage.setItem(`${DEVICE_ID_KEY}-${userId}`, deviceId);
  };
  
  const getOrCreateDeviceId = async (userId: string) => {
    const deviceId = loadDeviceId(userId);
    if (deviceId) { // Device already assigned
      return deviceId;
    }
    // Generate new deviceId and assign
    const uuid = generateDeviceId();
    storeDeviceId(uuid, userId);
    return uuid;
  };

  // End -> FB Generate Device Id

  const assignDevice = async (deviceId: string) => {
    // const response = await this._postCall(`api/devices/${deviceId}/assign`);
    const response = await fireblocks_postCall(`api/devices/${deviceId}/assign`, {})
    return response.walletId;
  }

  const loginToFireblocksBackend = async() => {
    await fireblocks_postCall(`api/login`, {});
  };

  /*
  https://ncw-developers.fireblocks.com/v4.0/docs/multiple-devices#approving-a-new-device
  */
  const approveJoinWallet = async (requestId: string, fireblocksNCW: IFireblocksNCW) => {
    if (!fireblocksNCW) {
      throw new Error("fireblocksNCW is not initialized");
    }
    const result = await fireblocksNCW.approveJoinWalletRequest(requestId);
    console.log("approveJoinWallet result:", result);
  };

  const findExistingWallets = async () => {
    try {
      const { devices } =  await fireblocks_getCall("api/devices/");
      console.log("Devices ", devices)
      if (devices.length > 0) {
        storeDeviceId(devices[0].deviceId, userId);
        return {deviceId: devices[0].deviceId, walletId: devices[0].walletId};
      }
      return null;
    } catch {
      return null;
    }
  };

  /*
  https://ncw-developers.fireblocks.com/v4.0/docs/multiple-devices#requesting-to-join-a-wallet
  */
  const joinExistingWallet = async (fireblocksNCW: IFireblocksNCW) => {
    if (!fireblocksNCW) {
      throw new Error("fireblocksNCW is not initialized");
    }
    await fireblocksNCW.requestJoinExistingWallet({
      onRequestId(requestId) {
        setRequestId(requestId);
      }
    });
  };

  const generateMPCKeys = async (fireblocksNCW: IFireblocksNCW) => {
    if (!fireblocksNCW) {
      throw new Error("fireblocksNCW is not initialized");
    }
    const ALGORITHMS = new Set([
      "MPC_CMP_ECDSA_SECP256K1"
    ]);
    await fireblocksNCW.generateMPCKeys(ALGORITHMS as Set<TMPCAlgorithm>);
  }

  const handleFireblocksInitiation = async (deviceId: string) => {
  // Register a message handler to process outgoing message to your API
  const messagesHandler = {
    handleOutgoingMessage: (message: string) => {
      if (!deviceId) {
        throw new Error("deviceId is not set");
      }
      return fireblocks_postCall(`api/devices/${deviceId}/rpc`, { message });
    },
  };

  // Register an events handler to handle on various events that the SDK emitts
  const eventsHandler: IEventsHandler = {
    handleEvent: (event) => {
      switch (event.type) {
        case "key_descriptor_changed":
          console.log("Key Status: ",keysStatus);
          keysStatus[event.keyDescriptor.algorithm] = event.keyDescriptor;
          setKeysStatus(keysStatus);
          break;

        case "transaction_signature_changed":
          console.log(`Transaction signature status: ${event.transactionSignature.transactionSignatureStatus}`);
          break;

        case "keys_backup":
          console.log(`Key backup status: ${JSON.stringify((event).keysBackup)}`);
          break;

        case "keys_recovery":
          console.log(`Key recover status: ${JSON.stringify((event).keyDescriptor)}`);
          break;

        case "join_wallet_descriptor":
          console.log(`join wallet event: ${JSON.stringify((event).joinWalletDescriptor)}`);
          break;
        default:
            break;
      }
    },
  };
    const logger = ConsoleLoggerFactory()

    const ncwInstance = getFireblocksNCWInstance(deviceId);
    if (ncwInstance) {
      console.log("Existing NCW Instance")
      setFireblocksSdk(ncwInstance);
      return ncwInstance;
    } else {
      console.log("New NCW Instance")
      const fireblocksNCWinit = await FireblocksNCWFactory({
          env: "production",
          logLevel: "INFO",
          deviceId,
          messagesHandler,
          eventsHandler,
          secureStorageProvider,
          logger,
        });
        setFireblocksSdk(fireblocksNCWinit);
        return fireblocksNCWinit;
    };
  };

  useEffect(() => {
    const handleFBLogin = async (userId: string) => {
      console.log("Handling FB login for userId: ", userId);
      await loginToFireblocksBackend();
      const deviceId = await getOrCreateDeviceId(userId);
      let existingDevice = await findExistingWallets();
      if (existingDevice) {
        await fireblocks_postCall(`api/devices/${deviceId}/join`, { walletId: existingDevice.walletId });
      }
      await assignDevice(deviceId);
      console.log("DeviceId ", deviceId);
      setDeviceId(deviceId);
      const fireblocksSdk = await handleFireblocksInitiation(deviceId);
      const ks = await fireblocksSdk.getKeysStatus();
      if (Object.values(ks).length === 0 || ks.MPC_CMP_ECDSA_SECP256K1.keyStatus === "ERROR") {
        console.log("Generating MPC keys!", ks)
        await generateMPCKeys(fireblocksSdk);
        let existingDevice = await findExistingWallets();
        if (existingDevice) { // A previous wallet was provisioned
          // https://ncw-developers.fireblocks.com/v4.0/docs/multiple-devices#supported-functions
          try { // Try to join with the initiated FB paired with this device
            console.log("A previous wallet was provisioned!")
            await joinExistingWallet(fireblocksSdk);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    if (userId) {
      console.log("Handling Authenticated User!")
      console.log("Handling FB login for userId: ", userId);
      handleFBLogin(userId);
    }
  }, [userId, userJWT]);

  useEffect(() => {
    console.log("Request id - ", requestId);
    if (Object.keys(requestId).length > 0) {
      console.log("Request id received!")
      approveJoinWallet(requestId, fbSdk);
    }
  }, [requestId]);

  return (
    <AppContext.Provider value={{ userId, setUserId, deviceId, setDeviceId, userJWT, setUserJWT, isAuthenticated, setIsAuthenticated, pasteCodeResult, setPasteCodeResult, reward, setReward, collectionIds, addCollectionId, removeCollectionId }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
}
